import React from "react"
import styled from "styled-components"
import { md } from "mill/utils/breakpoints"

const SplitViewContainer = styled.div`
  width: 100%;
  position: relative;

  @media ${md} {
    background-color: ${props => props.theme.colors.primary};
    display: flex;
    flex-direction: row-reverse;
  }
`

const MobileLogo = styled.img`
  position: absolute;
  top: 2rem;
  left: 2rem;
  max-height: 3rem;

  @media ${md} {
    display: none;
  }
`

const DesktopLogo = styled.img`
  position: absolute;
  top: 3rem;
  left: 3rem;
  max-height: 3rem;
  display: none;

  @media ${md} {
    display: inline-block;
  }
`

const CampaignInviteWrapper = ({ children }) => {
  const mobileLogo = window.THEME.reverseLogoUrl + "?auto=format&h=50"
  const desktopLogo = window.THEME.logoUrl + "?auto=format&h=30"

  return (
    <SplitViewContainer>
      <MobileLogo
        src={mobileLogo}
        srcSet={`${mobileLogo}&dpr=1 1x, ${mobileLogo}&dpr=2 2x, ${mobileLogo}&dpr=3 3x`}
      />
      <DesktopLogo
        src={desktopLogo}
        srcSet={`${desktopLogo}&dpr=1 1x, ${desktopLogo}&dpr=2 2x, ${desktopLogo}&dpr=3 3x`}
      />
      {children}
    </SplitViewContainer>
  )
}

export default CampaignInviteWrapper
