import styled from "styled-components"
import { sm, md, lg } from "mill/utils/breakpoints"
import colors from "shared/utils/colors"

export const ImageWindow = styled.div`
  background-color: ${props => props.theme.colors.primary};
  width: 100%;
  height: 50vh;
  max-height: 50rem;
  min-height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 2rem;
    ${props => props.landscape && `max-width: 26rem;`}
    ${props => props.portrait && `max-height: 18rem;`}
  }

  @media ${md} {
    background-color: none;
    width: 50%;
    min-height: 100vh;
    flex: 1;

    img {
      ${props => props.landscape && `max-width: 36rem;`}
      ${props => props.portrait && `max-height: 28rem;`}
      margin-bottom: 0;
    }
  }

  @media ${lg} {
    img {
      max-width: none;
      max-height: none;
    }
  }
`

export const ContentWindow = styled.div`
  width: 100%;
  height: 100%;

  button {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  @media ${sm} {
    padding-bottom: 3rem;
  }

  @media ${md} {
    width: 50%;
    min-height: 100vh;
    flex: 1;
    padding-bottom: 0;
    padding-top: 5rem;
    background-color: white;
    display: flex;
    align-items: center;
  }
`

export const Inner = styled.div`
  background-color: white;
  margin: 0 auto;
  padding: 3rem 1.5rem;
  width: 90%;
  border-radius: 1rem;
  max-width: 65rem;
  margin-top: -4rem;

  h1 {
    margin: 0;
    font-size: 1.8em;
  }

  hr {
    border: none;
    color: ${colors.grayAthens};
    background-color: ${colors.grayAthens};
    height: 1px;
    margin: 3rem 0;
  }

  button,
  span {
    width: 100%;
  }

  @media ${sm} {
    padding: 5rem;
    width: 95%;

    button,
    span {
      width: auto;
    }
  }

  @media ${md} {
    margin-top: 0;
    border-radius: 0;
    width: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // justify-content: space-around;
    ${props => props.paddingTop && `padding-top: 30%;`}
  }

  @media ${lg} {
    padding: 8rem;
  }
`

export const HelpLinks = styled.div`
  margin: 5rem auto;
  text-align: center;
  color: ${colors.grayAluminium};
  font-size: 1.5rem;

  a {
    color: ${colors.grayAluminium};
    text-decoration: none;

    &:visited {
      color: ${colors.grayAluminium};
    }

    &:hover {
      color: ${colors.grayMatterhorn};
    }
  }
`
